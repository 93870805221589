import utils from '../../utils';

const parseResponsePollsToPolls = (poll) => ({
  id: poll.id,
  completed: poll.question.votingCompleted === 'Y',
  question: utils.htmlToText(poll.text),
  dateBegin: new Date(poll.question.dateBegin.split('.').reverse().join('-')),
  dateEnd: new Date(poll.question.dateEnd.split('.').reverse().join('-')),
  variants: poll.variants.map((v) => ({
    id: v.id,
    answer: v.text,
    votes: poll?.votes?.[v.id] ? +poll.votes[v.id] : 0,
  })),
  voted: poll?.voted || null,
  totalVotes: Object.values(poll?.votes || {}).reduce((total, v) => (total + +v), 0),
});

export {
  parseResponsePollsToPolls,
};
