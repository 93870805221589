import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import screens from '@/router/types';
import auth from '@/middleware/auth';
import guest from '@/middleware/guest';
import middlewarePipeline from '@/router/middlewarePipeline';

const routes = [
  {
    path: '/',
    name: screens.MAIN_SCREEN,
    component: () => import('../screens/main/MainScreen'),
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: '',
        name: screens.HOME_SCREEN,
        component: () => import('../screens/main/MainHomeScreen'),
      },
      {
        path: 'meters-data',
        name: screens.METERS_DATA_SCREEN,
        component: () => import('../screens/main/MainMetersDataScreen'),
      },
      {
        path: 'receipt-data',
        name: screens.RECEIPT_SCREEN,
        component: () => import('../screens/main/MainReceiptScreen'),
      },
      {
        path: 'cars',
        name: screens.CARS_LIST_SCREEN,
        component: () => import('../screens/main/MainCarsListScreen'),
      },
    ],
  },
  {
    path: '/contacts',
    name: screens.CONTACTS_SCREEN,
    component: () => import('../screens/contacts/ContactsScreen'),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/sign',
    name: screens.AUTH_SCREEN,
    component: () => import('../screens/signin/SignInScreen'),
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/tickets',
    name: screens.TICKETS_SCREEN,
    component: () => import('../screens/tickets/TicketsScreen'),
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: '',
        name: screens.TICKETS_LIST_SCREEN,
        component: () => import('../screens/tickets/TicketsListScreen'),
      },
      {
        path: '/create',
        name: screens.CREATE_TICKETS_SCREEN,
        component: () => import('../screens/tickets/TicketsCreateScreen'),
      },
    ],
  },
  {
    path: '/news',
    name: screens.NEWS_SCREEN,
    component: () => import('../screens/news/NewsScreen'),
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: '',
        name: screens.NEWS_LIST_SCREEN,
        component: () => import('../screens/news/NewsListScreen'),
      },
      {
        path: ':id',
        name: screens.NEWS_DETAIL_SCREEN,
        component: () => import('../screens/news/NewsDetailScreen'),
      },
    ],
  },
  {
    path: '/polls',
    name: screens.POLLS_SCREEN,
    component: () => import('../screens/polls/PollsScreen'),
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: '',
        name: screens.POLLS_LIST_SCREEN,
        component: () => import('../screens/polls/PollsListScreen'),
      },
      {
        path: ':id',
        name: screens.POLLS_DETAIL_SCREEN,
        component: () => import('../screens/polls/PollsDetailScreen'),
      },
    ],
  },
  {
    path: '/services',
    name: screens.SERVICES_SCREEN,
    component: () => import('../screens/services/ServicesScreen'),
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: '',
        name: screens.SERVICES_LIST_SCREEN,
        component: () => import('../screens/services/ServicesListScreen'),
      },
      {
        path: ':id',
        name: screens.SERVICES_DETAIL_SCREEN,
        component: () => import('../screens/services/ServicesDetailScreen'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    }

    if (savedPosition) {
      return savedPosition;
    }

    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (!to.meta?.middleware) {
    return next();
  }
  
  const { middleware } = to.meta;
  const context = {
    to, from, next, store,
  };
  
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
