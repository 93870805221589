import http from '../http';

class News {
  static async fetchAll(offset, limit) {
    return await http.get('/news/', { params: { offset, limit } });
  }
  
  static async getById(id) {
    return await http.get(`/news/${id}/`);
  }

  static async fetchBanners() {
    return await http.get('/slider/');
  }
}

export default News;
