import Payment from '@/api/Payment';

const state = {
  paymentLink: null,
};

const mutations = {
  setPaymentLink(state, paymentLink) {
    state.paymentLink = paymentLink;
  },
};

const actions = {
  GET_PAYMENT_LINK: async ({ commit }) => {
    commit('setLoading', true);
    commit('cleanError');
  
    try {
      const { data } = await Payment.getLink();
    
      if (data?.code !== 200) {
        this.error = 'Не удалось получить ссылку для оплаты, попробуйте перезапустить приложение';
        return;
      }
    
      commit('setPaymentLink', data.data);
    } catch (e) {
      commit('setError', e.message);
    } finally {
      commit('setLoading', false);
    }
  },
};

const getters = {
  paymentLink: (state) => state.paymentLink,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
