<template>
  <button :class="['btn', type]" :disabled="disabled">
    <slot />
  </button>
</template>

<script>
const buttonViews = {
  GREEN: 'green',
  BORDER: 'border',
  TEXT_BLUE: 'text-blue',
  TEXT_RED: 'text-red',
};

export default {
  name: 'UiButton',
  props: {
    type: {
      type: String,
      default: buttonViews.GREEN,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  @include font-regular-caption-1;
  width: 100%;
  transition: 0.15s ease-in-out;
  user-select: none;

  &.green {
    padding: 10px 16px;
    background: var(--color-green);
    border-radius: 10px;
    border: 1px solid var(--color-green);
    color: white;

    &:disabled {
      background-color: var(--color-gray-light-06);
      color: var(--color-gray-dark-03);
      border-color: var(--color-gray-light-06);
      cursor: not-allowed;
    }

    &:not(:disabled):active {
      background: var(--color-green-dark);
      border-radius: 6px;
      border-color: var(--color-green-dark);
    }
  }

  &.border {
    padding: 10px 16px;
    border: 1px solid var(--color-divider);
    border-radius: 10px;
    color: var(--color-text);
    background: white;

    &:disabled {
      background-color: var(--color-gray-light-06);
      color: var(--color-gray-dark-03);
      border-color: var(--color-gray-light-06);
      cursor: not-allowed;
    }

    &:not(:disabled):active {
      background: white;
      border-radius: 6px;
      border-color: var(--color-green);
      color: var(--color-green);
    }
  }

  &.text-blue {
    padding: 10px 0;
    background: white;
    color: var(--color-text);
    border: 1px solid transparent;

    &:disabled {
      color: var(--color-gray-dark-03);
      cursor: not-allowed;
    }

    &:not(:disabled):active {
      color: var(--color-green);
    }
  }

  &.text-red {
    padding: 10px 0;
    color: var(--color-error);
    background: white;
    border: 1px solid transparent;

    &:disabled {
      color: var(--color-gray-dark-03);
      cursor: not-allowed;
    }

    &:not(:disabled):active {
      color: var(--color-green);
    }
  }
}
</style>
