import Push from '@/api/Push';
import store from '@/store';

const initPushService = () => {
  function processPush() {
    // alert(JSON.stringify(data));
  }
  
  /* eslint wrap-iife: "off" */
  (async function () {
    if (!window._firebaseExists) {
      return;
    }
    
    function sleepPromise(ms) {
      return new Promise((r) => {
        setTimeout(r, ms);
      });
    }
    
    function waitFirebase(total, interval) {
      /* eslint no-async-promise-executor: "off" */
      return new Promise(async (resolve, reject) => {
        while (true) {
          /* eslint no-await-in-loop: "off" */
          await sleepPromise(interval);
  
          if ((typeof Firebase) !== 'undefined') {
            resolve();
          }
  
          total -= interval;
          
          if (total < 0) {
            reject();
          }
        }
      });
    }
    
    try {
      await waitFirebase(1000 * 2, 1000);
    } catch (e) {
      console.log('Firebase wait timeouted!');
      return;
    }
    
    try {
      /* eslint no-undef: "off" */
      await Firebase.requestUserPermission();
    } catch (e) {
      return;
    }
    
    /* eslint no-undef: "off" */
    Firebase.getToken().then((token) => {
      Push.setToken(store.getters.userId, token);
    });
    
    /* eslint no-undef: "off" */
    Firebase.onTokenRefresh((token) => {
      Push.setToken(store.getters.userId, token);
    });
  
    /* eslint no-undef: "off" */
    Firebase.onMessage(processPush);
  })();
};

export default initPushService;
