import http from '../http';

class Services {
  static async getServ() {
    return await http.get('/services/');
  }

  static async getServItem(servId) {
    return await http.get(`/services/${servId}/`);
  }
}

export default Services;
