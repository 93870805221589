import utils from '../../utils';

const parseResponseNewsToNews = (news) => ({
  ...news,
  id: +news.id,
  previewText: news.previewText,
  date: news.activeFrom ? utils.dateFromTimestamp(news.activeFrom) : null,
  title: utils.htmlToText(news.name),
});

const parseResponseDetailToNews = (news) => ({
  ...news,
  id: +news.id,
  detailText: news.detailText,
  previewText: news.previewText,
  date: utils.dateFromTimestamp(news.activeFrom),
  title: utils.htmlToText(news.name),
});

const parseResponseBanners = (banners) => banners.map((b) => ({
  id: +b.id,
  image: b.detailPicture,
  link: b.propertyLinkValue,
  newsId: b.propertyNewsIdValue,
}));

export {
  parseResponseNewsToNews,
  parseResponseDetailToNews,
  parseResponseBanners,
};
