import http from '../http';

class Cars {
  static async get(userId) {
    return await http.get('/cars/', { params: { user: userId } });
  }

  static async create(userId, car) {
    return await http.post('/cars/', {}, { params: { accountId: userId, ...car } });
  }

  static async update(userId, car) {
    return await http.put(`/cars/${car.id}/`, {}, { params: { user: userId, ...car } });
  }

  static async remove(carId) {
    return await http.delete(`/cars/${carId}/`);
  }
}

export default Cars;
