<template>
	<svg xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :viewBox="`0 0 24 24`"
	>
		<g :fill="color">
			<slot />
		</g>
	</svg>
</template>

<script>
export default {
  name: 'UiIcon',
  props: {
    size: {
      type: [Number, String],
      default: 18,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>

<style scoped>

</style>
