import http from '../http';

class Debt {
  static async fetch(userId, date) {
    return await http.get('/debt/', { params: { user: userId, date } });
  }

  static async fetchMeters(userId) {
    return await http.get(`/meters/${userId}/`);
  }

  static async sendMetersData(id, value) {
    return await http.post(`/meter_value/${id}/`, {}, { params: { value: encodeURIComponent(value) } });
  }
}

export default Debt;
