import Cars from '@/api/Cars';
import { parseResponseCarToCar } from '@/store/services/carsServices';

const SECONDS_IN_DAY = 60 * 60 * 24;

const AVAILABLE_QUANTITY_USER_CARS = 4;
const AVAILABLE_QUANTITY_GUEST_CARS = 4;

export const CARS_TYPE = {
  USER_CAR: {
    value: 0,
    label: 'Семья',
  },
  GUEST_CAR: {
    value: 1,
    label: 'Гости',
  },
};

const state = {
  cars: [],
  availableQuantityUserCars: AVAILABLE_QUANTITY_USER_CARS,
  availableQuantityGuestCars: AVAILABLE_QUANTITY_GUEST_CARS,
};

const mutations = {
  setCars(state, cars) {
    // бек отдает всю свору просроченных и нормальных пропусков для машин
    state.cars = cars
      .filter((car) => +car.type === 0 || (+car.type === 1 && car.lockedTo * 1000 > new Date().getTime()))
      .map(parseResponseCarToCar);
  },
  setAvailableQuantity(state, { availableQuantityUserCars, availableQuantityGuestCars }) {
    state.availableQuantityUserCars = availableQuantityUserCars;
    state.availableQuantityGuestCars = availableQuantityGuestCars;
  },
};

const actions = {
  FETCH_CARS: async ({ getters, commit }) => {
    commit('setLoading', true);
    commit('cleanError');

    try {
      const { data } = await Cars.get(getters.userId);
      
      commit('setCars', data.data?.items || []);
      commit('setAvailableQuantity', {
        availableQuantityUserCars: data.data?.settings?.permanentNumber || AVAILABLE_QUANTITY_USER_CARS,
        availableQuantityGuestCars: data.data?.settings?.temporaryNumber || AVAILABLE_QUANTITY_GUEST_CARS,
      });
    } catch (e) {
      commit('setError', 'Ошибка при загрузке списка автомобилей');
    } finally {
      commit('setLoading', false);
    }
  },
  CREATE_CAR: async ({ getters, commit, dispatch }, car) => {
    commit('setLoading', true);
    commit('cleanError');
    
    const request = {
      ...car,
      type: CARS_TYPE[car.type].value,
    };
    
    if (car.type === 'GUEST_CAR' && car.dateStart) {
      // бек принимает таймштамп в секундах и нужно бронировать +1 день т.к. передаем день закрытия пропуска, а не день начала
      request.lockedTo = Math.ceil(new Date(car.dateStart).getTime() / 1000) + SECONDS_IN_DAY;
    }

    try {
      const { data } = await Cars.create(getters.userId, request);
      
      if (data.data.id) {
        dispatch('FETCH_CARS');
      } else {
        throw new Error();
      }
    } catch (e) {
      commit('setError', 'Ошибка при добавлении автомобиля');
    } finally {
      commit('setLoading', false);
    }
  },
  UPDATE_CAR: async ({ getters, commit, dispatch }, car) => {
    commit('setLoading', true);
    commit('cleanError');
  
    const request = { ...car };
  
    if (car.isGuest && car.dateStart) {
      // бек принимает таймштамп в секундах и нужно бронировать +1 день т.к. передаем день закрытия пропуска, а не день начала
      request.lockedTo = Math.ceil(new Date(car.dateStart).getTime() / 1000) + SECONDS_IN_DAY;
    }

    try {
      const { data } = await Cars.update(getters.userId, request);
  
      if (data.code === 200) {
        dispatch('FETCH_CARS');
      } else {
        throw new Error();
      }
    } catch (e) {
      commit('setError', 'Ошибка при обновлении данных автомобиля');
    } finally {
      commit('setLoading', false);
    }
  },
  REMOVE_CAR: async ({ commit, dispatch }, carId) => {
    commit('setLoading', true);
    commit('cleanError');

    try {
      const { data } = await Cars.remove(carId);
      
      if (data.code === 200) {
        dispatch('FETCH_CARS');
      } else {
        throw new Error();
      }
    } catch (e) {
      commit('setError', 'Ошибка при удалении автомобиля из списка');
    } finally {
      commit('setLoading', false);
    }
  },
};

const getters = {
  userCars: (state) => state.cars.filter((c) => !c.isGuest),
  guestCars: (state) => state.cars.filter((c) => c.isGuest),
  availableQuantityUserCars: (state) => state.availableQuantityUserCars,
  availableQuantityGuestCars: (state) => state.availableQuantityGuestCars,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
