import Contacts from '@/api/Contacts';

const state = {
  contacts: null,
};

const mutations = {
  setContacts(state, contacts) {
    state.contacts = contacts;
  },
};

const actions = {
  FETCH_CONTACTS: async ({ commit }) => {
    commit('setLoading', true);
    commit('cleanError');
    
    try {
      const { data } = await Contacts.get();
      
      if (data.code !== 200) {
        this.error = 'Не удалось загрузить контакты';
        return;
      }
      
      commit('setContacts', data.data);
      
      return true;
    } catch (e) {
      commit('setError', e.message);
    } finally {
      commit('setLoading', false);
    }
  },
};

const getters = {
  contacts: (state) => state.contacts,
  phone: (state) => state.contacts?.phone,
  email: (state) => state.contacts?.email,
  address: (state) => state.contacts?.address,
  urInfo: (state) => state.contacts?.urInfo,
  coordinates: (state) => (state.contacts ? [+state.contacts.lat, +state.contacts.lon] : null),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
