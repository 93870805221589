import Services from '@/api/Services';
import { parseResponseServToServ } from '@/store/services/servServices';

const state = {
  services: [],
};

const mutations = {
  setServices(state, serv) {
    state.services = Object.values(serv).map(parseResponseServToServ);
  },
};

const actions = {
  FETCH_SERVICES: async ({ commit }) => {
    commit('setLoading', true);
    commit('cleanError');

    try {
      const { data } = await Services.getServ();
      
      commit('setServices', data.data?.items || []);
    } catch (e) {
      commit('setError', 'Ошибка при загрузке списка сервисов');
    } finally {
      commit('setLoading', false);
    }
  },
};

const getters = {
  services: (state) => state.services,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
