const parseResponseProfileToProfile = (response) => ({
  fullName: response.userFullName?.trim(),
  email: response.userEmail,
  name: response.userName,
  lastName: response.userLastName,
  secondName: response.userSecondName,
  phone: response.userPersonalPhone,
  address: response.addressFull,
});

export {
  parseResponseProfileToProfile,
};
