<template>
	<g>
		<path d="M16.4327 13.9485C15.9649 13.9485 15.5856 14.3277 15.5856 14.7956C15.5856 16.8036 13.9517 18.4377 11.9433 18.4377C11.4754 18.4377 11.0962 18.8169 11.0962 19.2847C11.0962 19.7526 11.4754 20.1318 11.9433 20.1318C14.8857 20.1318 17.2797 17.7377 17.2797 14.7956C17.2798 14.3277 16.9006 13.9485 16.4327 13.9485Z"/>
		<path d="M20.1303 10.7845C20.1167 10.7616 20.1026 10.739 20.0868 10.7173L12.6891 0.354937C12.5304 0.132141 12.2735 0 11.9999 0C11.726 0 11.4694 0.132141 11.3107 0.354937L3.91301 10.7173C3.89777 10.739 3.8831 10.7613 3.86979 10.7845C2.35015 13.4055 2.37443 16.5871 3.93499 19.2957C5.60655 22.1975 8.69693 24 11.9999 24C15.3028 24 18.3932 22.1972 20.065 19.2954C21.6253 16.5871 21.6499 13.4053 20.1303 10.7845ZM18.5971 18.4498C17.2271 20.8283 14.6992 22.3059 11.9999 22.3059C9.30087 22.3059 6.77294 20.8286 5.40269 18.4501C4.13632 16.2517 4.10501 13.7822 5.31546 11.668L11.9999 2.30484L18.6843 11.6682C19.8945 13.7825 19.8634 16.2519 18.5971 18.4498Z"/>
	</g>
</template>

<script>
export default {
  name: 'IconWater',
};
</script>
