<template>
	<g>
		<path opacity="0.4" d="M14.4183 5.48976C13.9422 5.40182 13.505 5.70561 13.4144 6.17029C13.3238 6.63497 13.6285 7.08866 14.0916 7.1796C15.4859 7.45141 16.5624 8.53067 16.8353 9.92971V9.93071C16.913 10.3334 17.2675 10.6262 17.6759 10.6262C17.7306 10.6262 17.7854 10.6212 17.8412 10.6112C18.3043 10.5183 18.609 10.0656 18.5184 9.59994C18.1111 7.51037 16.5027 5.89648 14.4183 5.48976Z"/>
		<path opacity="0.4" d="M14.3558 2.00793C14.1328 1.97595 13.9087 2.04191 13.7304 2.18381C13.5472 2.32771 13.4326 2.53557 13.4078 2.76841C13.355 3.23908 13.6946 3.66479 14.1646 3.71776C17.4063 4.07951 19.9259 6.60477 20.2904 9.85654C20.3392 10.2922 20.7047 10.621 21.1409 10.621C21.1738 10.621 21.2057 10.619 21.2385 10.615C21.4666 10.59 21.6698 10.4771 21.8132 10.2972C21.9556 10.1174 22.0203 9.89351 21.9944 9.66467C21.5403 5.60746 18.4002 2.45862 14.3558 2.00793Z"/>
		<path d="M17.6047 14.6732C16.9563 14.5353 16.4435 14.8351 15.9893 15.0969C15.5242 15.3667 14.6389 16.0812 14.133 15.8974C11.5376 14.8341 9.09669 12.5706 8.04004 9.97642C7.8538 9.45977 8.56787 8.57338 8.83576 8.1057C9.09769 7.65201 9.39247 7.13537 9.25803 6.48582C9.13653 5.90122 7.56499 3.90959 7.00928 3.36496C6.64179 3.00521 6.26733 2.80734 5.88192 2.77436C4.43288 2.71341 2.81454 4.63908 2.53071 5.09977C1.81864 6.08209 1.82362 7.3892 2.54266 8.97411C4.27553 13.2312 10.8296 19.6558 15.1199 21.4465C15.9106 21.8153 16.6357 22.0002 17.286 22.0002C17.9224 22.0002 18.489 21.8233 18.976 21.4725C19.3425 21.2617 21.3562 19.5698 21.3025 18.0878C21.2716 17.7101 21.0734 17.3324 20.7169 16.9666C20.1731 16.408 18.1873 14.7961 17.6047 14.6732Z"/>
	</g>
</template>

<script>
export default {
  name: 'IconPhone',
};
</script>
