<template>
  <nav class="tab-bottom">
    <ul class="list">
      <li
        v-for="tab in tabs"
        :key="tab.routeName"
        class="item"
        :class="{ active: $route.name && $route.name.split('.').includes(tab.tag) }"
      >
				<router-link :to="{ name: tab.routeName }" class="link">
					<UiIcon>
            <component :is="tab.icon" />
          </UiIcon>
          <span>{{ tab.name }}</span>
				</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import screens from '@/router/types';
import UiIcon from '@/components/ui/UiIcon';
import { IconHome, IconMessage, IconDiscovery, IconChart, IconServices } from '@/components/ui/icons';

export default {
  name: 'AppTabBottom',
  components: { UiIcon, IconHome, IconMessage, IconDiscovery, IconChart, IconServices },
  data() {
    return {
      tabs: [
        {
          routeName: screens.HOME_SCREEN,
          tag: screens.MAIN_SCREEN,
          name: 'Главная',
          icon: 'IconHome',
        },
        {
          routeName: screens.TICKETS_LIST_SCREEN,
          tag: screens.TICKETS_SCREEN,
          name: 'Заявки',
          icon: 'IconMessage',
        },
        {
          routeName: screens.NEWS_LIST_SCREEN,
          tag: screens.NEWS_SCREEN,
          name: 'Новости',
          icon: 'IconDiscovery',
        },
        {
          routeName: screens.POLLS_LIST_SCREEN,
          tag: screens.POLLS_SCREEN,
          name: 'Опросы',
          icon: 'IconChart',
        },
        {
          routeName: screens.SERVICES_LIST_SCREEN,
          tag: screens.SERVICES_SCREEN,
          name: 'Услуги',
          icon: 'IconServices',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.tab-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 -1px 0 rgba(118, 147, 185, 0.16);
  backdrop-filter: blur(16px);
  z-index: 100;
}

.list {
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}

.item {
  flex: 0 0 20%;
  max-width: 20%;

  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 7px;
    padding-bottom: 5px;
    color: var(--color-green);

    @supports(padding: max(0px)) {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }

  span {
    margin-top: 3px;
    @include font-regular-caption-2;
  }

  &.active .link {
    background: var(--color-gray-light);
    color: var(--color-green-dark);
  }
}
</style>
