<template>
	<g>
		<path opacity="0.4" d="M22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.478 6.477 2 12 2C17.523 2 22 6.478 22 12Z"/>
		<path d="M15.8597 8.70481L14.2397 13.8248C14.1797 14.0348 14.0097 14.2048 13.7997 14.2658L8.69972 15.8648C8.35972 15.9758 8.02972 15.6448 8.13972 15.3048L9.73972 10.1748C9.79972 9.96481 9.96972 9.80481 10.1797 9.73481L15.2997 8.13481C15.6497 8.02481 15.9697 8.35481 15.8597 8.70481Z"/>
	</g>
</template>

<script>
export default {
  name: 'IconDiscovery',
};
</script>
