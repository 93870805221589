const state = {
  error: null,
};

const mutations = {
  setError(state, error) {
    state.error = error;
  },
  cleanError(state) {
    state.error = null;
  },
};

const actions = {};

const getters = {
  error: (state) => state.error,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
