import Profile from '@/api/Profile';
import { parseResponseProfileToProfile } from '@/store/services/profileServices';

const state = {
  profile: null,
};

const mutations = {
  setProfile(state, profile) {
    state.profile = profile;
  },
};

const actions = {
  FETCH_PROFILE: async ({ commit, getters }) => {
    commit('setLoading', true);
    commit('cleanError');
  
    try {
      const { data } = await Profile.fetch(getters.userId);
    
      commit('setProfile', parseResponseProfileToProfile(data.data));
    } catch (e) {
      commit('setError', e.message);
    } finally {
      commit('setLoading', false);
    }
  },
};

const getters = {
  profile: (state) => state.profile,
  userFullName: (state) => state.profile?.fullName || '',
  userEmail: (state) => state.profile?.email || '',
  userPhone: (state) => state.profile?.phone || '',
  userAddress: (state) => state.profile?.address || '',
};

export default {
  state,
  mutations,
  actions,
  getters,
};
