import { createApp } from 'vue';
import screens from '@/router/types';
import { vfmPlugin } from 'vue-final-modal';
import vClickOutside from 'click-outside-vue3';
import initPushService from '@/Services/PushFirebase';
import store from './store';
import App from './App';
import router from './router';
import './scss/index.scss';

initPushService();

const app = createApp(App)
  .use(store)
  .use(router)
  .use(vfmPlugin({
    key: '$vfm',
    componentName: 'VueFinalModal',
    dynamicContainerName: 'ModalsContainer',
  }))
  .use(vClickOutside);

app.config.globalProperties.$screens = screens;

const userId = localStorage.getItem('userId');
if (userId) {
  store.commit('setUserId', +userId);
}

app.mount('#app');
