<template>
	<g>
		<path d="M9.375 17.8333H8.125C7.62772 17.8333 7.15081 17.649 6.79917 17.3208C6.44754 16.9926 6.25 16.5475 6.25 16.0833V7.91667C6.25 7.45254 6.44754 7.00742 6.79917 6.67923C7.15081 6.35104 7.62772 6.16667 8.125 6.16667H9.375C9.54076 6.16667 9.69973 6.10521 9.81694 5.99581C9.93415 5.88642 10 5.73804 10 5.58333C10 5.42862 9.93415 5.28025 9.81694 5.17085C9.69973 5.06146 9.54076 5 9.375 5H8.125C7.2965 5.00093 6.50222 5.30851 5.91639 5.85529C5.33055 6.40208 5.00099 7.1434 5 7.91667L5 16.0833C5.00099 16.8566 5.33055 17.5979 5.91639 18.1447C6.50222 18.6915 7.2965 18.9991 8.125 19H9.375C9.54076 19 9.69973 18.9385 9.81694 18.8291C9.93415 18.7197 10 18.5714 10 18.4167C10 18.262 9.93415 18.1136 9.81694 18.0042C9.69973 17.8948 9.54076 17.8333 9.375 17.8333Z"/>
		<path d="M15.8376 15.8255L18.4932 13.205C18.8178 12.883 19 12.4472 19 11.993C19 11.5387 18.8178 11.103 18.4932 10.781L15.8376 8.16041C15.7284 8.05632 15.5822 7.99872 15.4304 8.00002C15.2786 8.00132 15.1334 8.06142 15.026 8.16737C14.9187 8.27332 14.8578 8.41664 14.8565 8.56646C14.8552 8.71629 14.9135 8.86063 15.019 8.96841L17.5038 11.4215H8.57893C8.42539 11.4215 8.27814 11.4818 8.16957 11.5889C8.06099 11.6961 8 11.8414 8 11.993C8 12.1445 8.06099 12.2899 8.16957 12.397C8.27814 12.5042 8.42539 12.5644 8.57893 12.5644H17.5043L15.019 15.0175C14.9637 15.0703 14.9196 15.1333 14.8892 15.203C14.8589 15.2727 14.8429 15.3477 14.8423 15.4236C14.8416 15.4995 14.8562 15.5747 14.8854 15.645C14.9145 15.7152 14.9574 15.779 15.0118 15.8326C15.0662 15.8863 15.1308 15.9287 15.202 15.9574C15.2731 15.9862 15.3493 16.0006 15.4262 16C15.5031 15.9993 15.579 15.9836 15.6497 15.9536C15.7203 15.9237 15.7842 15.8801 15.8376 15.8255Z"/>
	</g>
</template>

<script>
export default {
  name: 'IconSignOut',
};
</script>
