import utils from '../../utils';

const parseResponseCarToCar = (car) => ({
  ...car,
  id: +car.id,
  isGuest: +car.type === 1,
  start: car.temporaryDtStart ? utils.dateFromTimestamp(car.temporaryDtStart) : null,
  lockedTo: car.lockedTo ? utils.dateFromTimestamp(car.lockedTo) : null,
});

export {
  parseResponseCarToCar,
};
