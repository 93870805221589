const parseResponseDebt = (debt) => {
  const parsedDebt = {};

  debt.forEach((d) => {
    const year = new Date(d.periodDate).getFullYear();

    if (!parsedDebt[year]) {
      parsedDebt[year] = [];
    }

    parsedDebt[year].push(d);
  });
  
  return parsedDebt;
};

export {
  parseResponseDebt,
};
