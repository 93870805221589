import axios from 'axios';
import humps from 'humps';

const instance = axios.create({
  baseURL: process.env.VUE_APP_SERVER,
  transformResponse: [
    ...axios.defaults.transformResponse,
    (data) => humps.camelizeKeys(data, (key, convert) => (convert(key.toLowerCase()))),
  ],
  transformRequest: [
    (data) => humps.decamelizeKeys(data),
    ...axios.defaults.transformRequest,
  ],
});

instance.interceptors.request.use((config) => ({
  ...config, params: { ...humps.decamelizeKeys(config.params), code: process.env.VUE_APP_API_TOKEN },
}));

instance.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';

export default instance;
