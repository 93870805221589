import StringFormating from './StringFormating';

const MINUTES = 1000 * 60;
const HOUR = MINUTES * 60;
const DAY = HOUR * 24;

class DateFormating {
  static month = [
    'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
    'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря',
  ];
  
  static periodToString(ds, de) {
    if (ds.getFullYear() === de.getFullYear()) {
      if (ds.getMonth() === de.getMonth()) {
        // с день по день месяц
        return `C ${ds.getDate()} по ${de.getDate()} ${DateFormating.month[de.getMonth()]}`;
      }

      // с день месяц по день месяц
      return `C ${ds.getDate()} ${DateFormating.month[ds.getMonth()]} по ${de.getDate()} ${DateFormating.month[de.getMonth()]}`;
    }
  
    // с день месяц год по день месяц год
    return `C ${ds.getDate()} ${DateFormating.month[ds.getMonth()]} ${ds.getFullYear()} по ${de.getDate()} ${DateFormating.month[de.getMonth()]} ${de.getFullYear()}`;
  }
  
  static lostTimeToString(lostMilliseconds) {
    if (lostMilliseconds <= 0) {
      return '';
    }
    
    const lostDays = Math.floor(lostMilliseconds / DAY);
    const lostHours = Math.floor(lostMilliseconds / HOUR);
    const lostMinutes = Math.floor(lostMilliseconds / MINUTES);
    
    if (lostDays >= 1) {
      return `${lostDays} ${StringFormating.prupal(lostDays, ['день', 'дня', 'дней'])}`;
    }

    if (lostHours >= 1) {
      return `${lostHours} ${StringFormating.prupal(lostHours, ['час', 'часа', 'часов'])}`;
    }
 
    return `${lostMinutes} ${StringFormating.prupal(lostMinutes, ['минута', 'минуты', 'минут'])}`;
  }
  
  static getISOString(date) {
    const pad = (number) => ((number < 10) ? `0${number}` : number);

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }
}

export default DateFormating;
