import http from '../http';

class Polls {
  static async getAll(user, offset, limit) {
    return await http.get('/votes/', { params: { user, offset, limit } });
  }

  static async getById(id) {
    return await http.get(`/votes/${id}/`);
  }

  static async vote(userId, question, answer) {
    return await http.post('/votes/', {}, { params: { user: userId, question, answer } });
  }
}

export default Polls;
