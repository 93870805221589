import Auth from '@/api/Auth';

const state = {
  userId: null,
};

const mutations = {
  setUserId(state, userId) {
    if (userId) {
      localStorage.setItem('userId', userId);
    } else {
      localStorage.removeItem('userId');
    }

    state.userId = userId;
  },
};

const actions = {
  AUTH: async ({ commit }, credential) => {
    commit('setLoading', true);
    commit('cleanError');
  
    try {
      const { data } = await Auth.auth(credential);
    
      if (data?.code !== 200) {
        this.error = 'Не удалось авторизоваться, проверьте данные для входа';
        return;
      }
    
      commit('setUserId', data.data.id);
      localStorage.setItem('userId', data.data.id);
      
      return true;
    } catch (e) {
      if (/404/.test(e.message)) {
        commit('setError', 'Не удалось авторизоваться, проверьте данные для входа');
        return;
      }
      commit('setError', e.message);
    } finally {
      commit('setLoading', false);
    }
  },
  LOGOUT: ({ commit }) => {
    commit('setUserId', null);
  },
};

const getters = {
  isAuth: (state) => state.userId !== null,
  userId: (state) => state.userId,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
