const dateFromTimestamp = (timestamp) => (new Date(timestamp * 1000));

const toLocaleDateString = (date, opts) => (date.toLocaleDateString('ru-RU', opts));

const htmlToText = (str) => (
  str.toString()
    .replace(/<[^>]*>?/gm, '')
    .replaceAll('&nbsp;', ' ')
    .replaceAll('&laquo;', '«')
    .replaceAll('&raquo;', '»')
    .replaceAll('&quot;', '"')
);

export default {
  dateFromTimestamp,
  toLocaleDateString,
  htmlToText,
};
