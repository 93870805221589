const screens = {
  AUTH_SCREEN: 'AUTH_SCREEN',
  
  MAIN_SCREEN: 'MAIN',
  HOME_SCREEN: 'MAIN.HOME',
  RECEIPT_SCREEN: 'MAIN.RECEIPT',
  METERS_DATA_SCREEN: 'MAIN.METERS_DATA',
  CARS_LIST_SCREEN: 'MAIN.CARS_LIST',
  
  CONTACTS_SCREEN: 'MAIN.CONTACTS',

  TICKETS_SCREEN: 'TICKETS',
  TICKETS_LIST_SCREEN: 'TICKETS.LIST',
  CREATE_TICKETS_SCREEN: 'TICKETS.CREATE',
  
  NEWS_SCREEN: 'NEWS',
  NEWS_LIST_SCREEN: 'NEWS.LIST',
  NEWS_DETAIL_SCREEN: 'NEWS.DETAIL',
  
  POLLS_SCREEN: 'POLLS',
  POLLS_LIST_SCREEN: 'POLLS.LIST',
  POLLS_DETAIL_SCREEN: 'POLLS.DETAIL',
  
  SERVICES_SCREEN: 'SERVICES',
  SERVICES_LIST_SCREEN: 'SERVICES.LIST',
  SERVICES_DETAIL_SCREEN: 'SERVICES.DETAIL',
};

export default screens;
