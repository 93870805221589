import Polls from '@/api/Polls';
import { parseResponsePollsToPolls } from '@/store/services/pollsServices';

const state = {
  polls: [],
  totalPolls: null,
  pagePolls: 0,
  limitPolls: 5,
};

const mutations = {
  setPollsList(state, { polls, total }) {
    state.polls = polls.map(parseResponsePollsToPolls);
    state.totalPolls = total;
    state.pagePolls = 1;
  },
  addPollsList(state, polls) {
    state.polls = [...state.polls, ...polls.map(parseResponsePollsToPolls)];
    state.pagePolls += 1;
  },
};

const actions = {
  FETCH_POLLS: async ({ getters, commit }, page) => {
    commit('setLoading', true);
    commit('cleanError');
    const offset = (page || getters.pagePolls) * getters.limitPolls;
    
    try {
      const { data } = await Polls.getAll(getters.userId, offset, getters.limitPolls);

      if (page) {
        commit('addPollsList', data.data?.items || []);
      } else {
        commit('setPollsList', { polls: data.data?.items || [], total: data.data?.count || 0 });
      }
    } catch (e) {
      commit('setError', 'Ошибка при загрузке списка опросов');
    } finally {
      commit('setLoading', false);
    }
  },
  VOTE: async ({ getters, commit, dispatch }, { question, answer }) => {
    commit('setLoading', true);
    commit('cleanError');
    
    try {
      const { data } = await Polls.vote(getters.userId, question, answer);
      
      if (data.code === 200) {
        dispatch('FETCH_POLLS', 0);
      } else {
        throw new Error();
      }
    } catch (e) {
      commit('setError', 'Ошибка при голосовании, попробуйте позже');
    } finally {
      commit('setLoading', false);
    }
  },
};

const getters = {
  pollById: (state) => (id) => state.polls.find((p) => p.id === id),
  limitPolls: (state) => state.limitPolls,
  pagePolls: (state) => state.pagePolls,
  openedPolls: (state) => state.polls.filter((p) => !p.completed),
  closedPolls: (state) => state.polls.filter((p) => p.completed),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
