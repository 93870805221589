import { createStore } from 'vuex';

import errors from '@/store/modules/errors';
import auth from '@/store/modules/auth';
import news from '@/store/modules/news';
import contacts from '@/store/modules/contacts';
import cars from '@/store/modules/cars';
import tickets from '@/store/modules/tickets';
import profile from '@/store/modules/profile';
import services from '@/store/modules/services';
import polls from '@/store/modules/polls';
import debt from '@/store/modules/debt';
import payment from '@/store/modules/payment';

export default createStore({
  state: {
    isLoading: false,
  },
  getters: {
    isLoading: (state) => state.isLoading,
  },
  mutations: {
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  actions: {},
  modules: {
    errors,
    auth,
    news,
    contacts,
    cars,
    tickets,
    services,
    profile,
    polls,
    debt,
    payment,
  },
});
