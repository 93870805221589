import News from '@/api/News';
import { parseResponseBanners, parseResponseDetailToNews, parseResponseNewsToNews } from '@/store/services/newsServices';

const state = {
  news: [],
  total: null,
  page: 0,
  limit: 10,
  banners: [],
};

const mutations = {
  setNewsList(state, { news, total }) {
    state.news = news.map(parseResponseNewsToNews);
    state.total = total;
    state.page = 1;
  },
  addNewsList(state, news) {
    state.news = [...state.news, ...news.map(parseResponseNewsToNews)];
    state.page += 1;
  },
  setBanners(state, banners) {
    state.banners = banners;
  },
};

const actions = {
  FETCH_NEWS: async ({ getters, commit }, page) => {
    commit('setLoading', true);
    commit('cleanError');
    const offset = (page || getters.page) * getters.limit;

    try {
      const { data } = await News.fetchAll(offset, getters.limit);
      
      if (!page) {
        commit('addNewsList', data.data?.items || []);
      } else {
        commit('setNewsList', { news: data.data?.items || [], total: data.data?.count || 0 });
      }
    } catch (e) {
      commit('setError', 'Ошибка при загрузке списка новостей');
    } finally {
      commit('setLoading', false);
    }
  },
  GET_NEWS_BY_ID: async ({ commit }, id) => {
    commit('setLoading', true);
    commit('cleanError');

    try {
      const { data } = await News.getById(id);
      
      return parseResponseDetailToNews(data.data?.items[0] || {});
    } catch (e) {
      commit('setError', 'Не удалось загрузить новость');
    } finally {
      commit('setLoading', false);
    }
  },
  FETCH_BANNERS: async ({ commit }) => {
    commit('setLoading', true);
    commit('cleanError');

    try {
      const { data } = await News.fetchBanners();
  
      commit('setBanners', parseResponseBanners(data.data?.items || []));
    } catch (e) {
      commit('setError', 'Не удалось загрузить актуальные новости');
    } finally {
      commit('setLoading', false);
    }
  },
};

const getters = {
  limit: (state) => state.limit,
  page: (state) => state.page,
  banners: (state) => state.banners,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
