import utils from '../../utils';

const parseResponseServItemToServItem = (item) => ({
  ...item,
  id: +item.id,
  previewText: item.previewText && utils.htmlToText(item.previewText),
  price: item.propertyPriceValue && item.propertyPriceValue.match(/\d+/, '')?.[0],
  measure: item.propertyMeasureValue || 'шт',
  title: utils.htmlToText(item.name),
});

const parseResponseServToServ = (serv) => ({
  ...serv,
  id: +serv.id,
  title: utils.htmlToText(serv.name),
  items: serv.items.map(parseResponseServItemToServItem),
});

export {
  parseResponseServToServ,
  parseResponseServItemToServItem,
};
