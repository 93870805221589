import Tickets from '@/api/Tickets';

const state = {
  tickets: [],
};

const mutations = {
  setTickets(state, tickets) {
    state.tickets = tickets;
  },
  addTicket(state, ticket) {
    state.tickets.push(ticket);
  },
};

const actions = {
  FETCH_TICKETS: async ({ getters, commit }) => {
    commit('setLoading', true);
    commit('cleanError');

    try {
      const { data } = await Tickets.getAll(getters.userId);
      
      commit('setTickets', data.data?.items || []);
    } catch (e) {
      commit('setError', 'Ошибка при загрузке списка заявок');
    } finally {
      commit('setLoading', false);
    }
  },
  CREATE_TICKET: async ({ getters, commit }, question) => {
    commit('setLoading', true);
    commit('cleanError');

    try {
      const { data } = await Tickets.create(getters.userId, {
        question,
        authorName: getters.userFullName,
        authorAddress: getters.userAddress,
        authorPhone: getters.userPhone,
        authorEmail: getters.userEmail,
      });

      if (!data?.data?.count) {
        commit('setError', 'Ошибка при отправке заявки');
        return;
      }

      commit('addTicket', data.data.items[0]);
    } catch (e) {
      commit('setError', 'Ошибка при отправке заявки');
    } finally {
      commit('setLoading', false);
    }
  },
};

const getters = {
  tickets: (state) => state.tickets,
  openedTickets: (state) => state.tickets.filter((t) => !t.answer?.trim()),
  closedTickets: (state) => state.tickets.filter((t) => t.answer?.trim()),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
