import Debt from '@/api/Debt';
import { parseResponseDebt } from '@/store/services/debtServices';
import DateFormating from '@/Services/DateFormating';

const state = {
  debt: [],
  receipt: {
    value: 0,
    date: '',
  },
  water: [],
  electricity: [],
  months: [],
  metersData: [],
};

const mutations = {
  setDebt(state, debt) {
    state.debt = debt;
    
    debt.forEach((d) => {
      state.months.push(new Date(d.periodDate).toLocaleDateString('ru-RU', { month: '2-digit' }));
    });
    
    const lastMonth = debt.at(-1);
    const lastPeriodDate = new Date(lastMonth?.periodDate);
  
    state.receipt = {
      value: lastMonth?.debtEnd || 0,
      date: `${lastPeriodDate.getDay()} ${DateFormating.month[lastPeriodDate.getMonth()]}`,
    };
  },
  setMetersGraphDataset(state, debt) {
    debt.forEach((d) => {
      state.water.push(Math.round(Number(d.charge.find((c) => c.serviceName === 'Водоснабжение хол')?.amount || 0) * 10) / 10);
      state.electricity.push(Math.round(Number(d.charge.find((c) => c.serviceName === 'Электроэнергия')?.amount || 0) * 10) / 10);
    });
  },
  setMetersData(state, data) {
    state.metersData = data;
  },
};

const actions = {
  FETCH_DEBT: async ({ commit, getters }, date = '') => {
    commit('setLoading', true);
    commit('cleanError');
  
    try {
      const { data } = await Debt.fetch(getters.userId, date);
    
      if (data.code !== 200) {
        this.error = 'Не удалось получить данные';
        return;
      }
    
      commit('setDebt', data.data?.items || []);
      commit('setMetersGraphDataset', data.data?.items || []);
    } catch (e) {
      commit('setError', e.message);
    } finally {
      commit('setLoading', false);
    }
  },
  FETCH_METERS_DATA: async ({ commit, getters }) => {
    commit('setLoading', true);
    commit('cleanError');
  
    try {
      const { data } = await Debt.fetchMeters(getters.userId);
    
      commit('setMetersData', data.data || []);
    } catch (e) {
      commit('setError', e.message);
    } finally {
      commit('setLoading', false);
    }
  },
  SEND_METERS_DATA: async ({ commit, dispatch }, { meters, value }) => {
    commit('setLoading', true);
    commit('cleanError');
  
    try {
      const { data } = await Debt.sendMetersData(meters, value);
      
      await dispatch('FETCH_METERS_DATA');
      
      return data.code === 200;
    } catch (e) {
      commit('setError', e.message);
    } finally {
      commit('setLoading', false);
    }
  },
};

const getters = {
  debt: (state) => state.debt,
  debtGroupYear: (state) => parseResponseDebt(state.debt),
  paymentHistory: (state) => state.debt.reverse().map((d) => ({
    id: d.id,
    date: new Date(d.periodDate).toLocaleDateString('ru-RU', { month: 'long', year: 'numeric' }),
    total: Number(d.debtEnd).toLocaleString('ru-RU'),
  })),
  receipt: (state) => state.receipt,
  water: (state) => state.water,
  electricity: (state) => state.electricity,
  months: (state) => state.months,
  metersData: (state) => state.metersData,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
