<template>
  <div ontouchstart>
    <AppErrorsHandler>
      <div class="wrap">
        <div class="content">
          <router-view v-slot="{ Component, route }">
            <transition name="fade-route">
              <component :is="Component" :key="route.path" />
            </transition>
          </router-view>
        </div>
        <AppTabBottom v-if="isAuth" />
      </div>
    </AppErrorsHandler>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import AppErrorsHandler from '@/components/AppErrorsHandler';
import AppTabBottom from '@/components/AppTabBottom';

export default {
  components: { AppTabBottom, AppErrorsHandler },
  setup() {
    const store = useStore();

    const isAuth = computed(() => store.getters.isAuth);

    if (isAuth.value) {
      store.dispatch('FETCH_PROFILE');
      store.dispatch('FETCH_DEBT');
      store.dispatch('FETCH_METERS_DATA');
      store.dispatch('GET_PAYMENT_LINK');
    }

    return {
      isAuth,
    };
  },
};
</script>
