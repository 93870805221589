<template>
  <div>
    <slot />

    <vue-final-modal :model-value="isShow" @click-outside="close">
      <div class="error">
        {{ error }}
      </div>
      <div class="btns">
        <UiButton type="text-blue" @click="close">Хорошо</UiButton>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref, watch } from 'vue';
import UiButton from '@/components/ui/UiButton';

export default {
  name: 'AppErrorsHandler',
  components: { UiButton },
  setup() {
    const store = useStore();

    const error = computed(() => store.getters.error);
    const isShow = ref(false);

    watch(error, (val) => {
      val && (isShow.value = true);
    });

    const close = () => {
      isShow.value = false;
      setTimeout(() => { store.commit('cleanError'); }, 100);
    };

    return {
      isShow,
      error,
      close,
    };
  },
};
</script>

<style scoped lang="scss">
.error {
  @include font-regular-body;
  color: black;
  padding: 17px 10px;
}
hr {
  margin-top: 6px;
  margin-bottom: 6px;
}
</style>
