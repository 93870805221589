<template>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.156 18.7608C17.156 17.6615 17.0635 17.9334 17.4985 17.4985C17.9334 17.0635 17.6615 17.156 18.7608 17.156C18.8615 17.156 19.5044 17.799 19.5044 17.8997C19.5044 18.999 19.597 18.727 19.162 19.162C18.727 19.597 18.999 19.5044 17.8997 19.5044C17.799 19.5044 17.156 18.8615 17.156 18.7608ZM6.25348 2.4H6.74461C8.19463 2.4 9.60626 3.45413 10.2417 4.77401C10.6449 5.61152 10.7814 7.02827 10.3944 7.86994L16.9603 14.4553C17.2229 14.3293 17.7786 14.2205 18.1736 14.2205C19.9252 14.2205 21.2686 15.0196 22.0356 16.5427C22.4081 17.2823 22.44 17.753 22.44 18.5651C22.44 19.588 21.888 20.5848 21.2364 21.2364C20.5501 21.9228 19.5728 22.44 18.5651 22.44C17.753 22.44 17.2823 22.4081 16.5427 22.0356C16.0237 21.7742 15.7927 21.6048 15.4045 21.2168C14.7334 20.5462 14.2205 19.5272 14.2205 18.4868C14.2205 17.2859 14.5358 17.038 14.3678 16.8557L13.2466 15.7423C13.2408 15.7367 13.2326 15.7285 13.2269 15.7229C13.2212 15.7171 13.2131 15.709 13.2074 15.7032L12.9331 15.4295C12.9275 15.4238 12.9192 15.4157 12.9137 15.4099L11.7484 14.2658C11.4295 13.8665 9.06833 11.5578 8.3592 10.8446C8.12661 10.6106 7.96335 10.5514 7.91884 10.3847C7.71502 10.4322 7.56957 10.4992 7.33608 10.5456C6.92256 10.6278 6.76757 10.6195 6.35321 10.6195C4.73675 10.6195 3.43386 9.54718 2.80438 8.29727C2.56546 7.82286 2.43083 7.29038 2.4 6.74833V6.26358C2.41355 6.02462 2.44708 5.78658 2.50063 5.5536C2.62453 5.01468 3.08706 4.87757 3.40273 5.07641L5.38437 7.0674L7.05774 5.37469C6.95657 5.30694 5.12715 3.49928 5.06178 3.37806C4.87303 3.02798 5.05247 2.60924 5.58591 2.4938C5.90058 2.4257 6.06468 2.40528 6.25348 2.4Z"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.91884 14.4553C7.11769 14.2687 7.35286 14.2205 6.35321 14.2205C4.25717 14.2205 2.4 16.1892 2.4 18.0954V18.4028C2.40451 18.8863 2.448 19.5084 2.78305 19.7477C2.94948 19.8665 3.19719 19.8757 3.37806 19.7782C3.49929 19.7129 5.30694 17.8834 5.37469 17.7823L7.04829 19.4356C6.9261 19.6228 6.48257 20.0209 6.28471 20.2188L5.0925 21.4141C4.88358 21.7022 4.97635 22.2066 5.5536 22.3393C5.79041 22.3938 6.02506 22.4264 6.25619 22.44H6.73655C8.88947 22.3138 10.6195 20.5045 10.6195 18.4868C10.6195 17.9675 10.5891 17.3863 10.3847 16.9603L11.6077 15.7566C11.4909 15.5866 9.74542 13.8749 9.41596 13.5452C9.30351 13.4328 9.2172 13.2856 9.05391 13.242C8.98257 13.5091 7.97179 14.257 7.91884 14.4553Z"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2204 6.35321C14.2204 6.81902 14.2184 7.04473 14.3291 7.49706C14.4308 7.91294 14.5184 7.83666 14.3047 8.04115L13.2419 9.09306L15.7372 11.6078L16.9699 10.3943C17.8115 10.7815 19.2284 10.6449 20.0659 10.2417C21.3858 9.60625 22.4399 8.19463 22.4399 6.74461V6.43712C22.4354 5.95372 22.3919 5.33153 22.0568 5.09231C21.5981 4.76471 21.246 5.25745 21.0407 5.46275C20.7636 5.73977 20.5153 5.98811 20.2382 6.26514C20.0365 6.46684 19.6261 6.92383 19.4261 7.05774C19.2322 6.76804 17.8835 5.60731 17.8213 5.37469C17.9789 5.13946 19.6702 3.55102 19.7635 3.40273C19.9624 3.08706 19.8252 2.62453 19.2863 2.50063C19.0534 2.44708 18.8153 2.41355 18.5764 2.4H18.0916C17.5495 2.43083 17.0171 2.56546 16.5427 2.80438C15.2928 3.43386 14.2204 4.73675 14.2204 6.35321Z"/>
</template>

<script>
export default {
  name: 'IconServices',
};
</script>
