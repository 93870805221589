import http from '../http';

class Tickets {
  static async getAll(userId) {
    return await http.get('/tickets/', { params: { user: userId } });
  }

  static async create(userId, data) {
    return await http.post('/tickets/', {}, { params: { user: userId, ...data } });
  }
}

export default Tickets;
